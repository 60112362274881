<template>
  <section class="h-screen" id="callToAction">
    <div class="container p-0.5 mx-auto text-center pt-10">
      <h1
          class="text-6xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mt-32 sm:mt-32 md:mt-36 lg:mt-40 callToActionText1 mb-12">
        Welcome To Delicias</h1>

      <router-link to="/products" class="text-2xl px-10 py-4 rounded-lg"
                   id="callToActionButton">Order Now
      </router-link>
    </div>
  </section>

  <section class="mb-28" id="famousProducts">
    <div class="flex items-center justify-center" id="products">


      <!-- Left animation wrapper with controlled size -->
      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-left-1" class="animation-left w-full h-full"></div>
      </div>

      <!-- Central text -->
      <div class="text-center mt-28 mb-4">
        <!-- Container with relative positioning and responsive width -->
        <div class="">

          <div class="ml-0">
            <h1 class="text-5xl sm:text-5xl md:text-6xl lg:text-7xl
                xl:text-6xl font-bold
    p-2 max-w-full section-heading text-black">Our Famous Products
            </h1>

            <h3 class="text-dark text-xl sm:text-xl md:text-2xl lg:text-2xl
                xl:text-2xl font-bold
    p-2 max-w-full section-heading" style="font-family: 'Lora', Serif;">
              See What The Crave Is About
            </h3>
          </div>
        </div>

      </div>

      <!-- Right animation wrapper with controlled size -->
      <div class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center
    justify-center">
        <div id="animation-right-1" class="animation-right w-full h-full"></div>
      </div>
    </div>

    <div class="grid above-two-cols:grid-cols-4 two-cols:grid-cols-2 one-col:grid-cols-1
    gap-14 p-12 sm:p-12 md:p-24 lg:p-20 xl:p-24
    m-4">
      <!-- Product Card -->
      <div
          class="product-card relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl border-none"
          style="background-color:
        #8B4513;" data-delay="0.0s">
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
        <!-- Product Image -->
        <img src="/images/products/cherry_strudel.jpeg" alt="Product Name" class="w-full h-60
    object-cover border-none outline-none">

        <!-- Product Details -->
        <div class="p-4 text-center" style="background-color: #8B4513;">
          <h3 class="text-lg font-semibold mb-2 text-white text-center"
              style="font-family: 'Lora', serif;">Cherry Strudel</h3>

          <!-- Add to Cart Button -->

        </div>
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">

      </div>

      <div
          class="product-card relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl border-none"
          style="background-color:
        #8B4513;" data-delay="0.3s">
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
        <!-- Product Image -->
        <img src="/images/products/almojabanas.jpeg" alt="Product Name" class="w-full h-60
    object-cover">

        <!-- Product Details -->
        <div class="p-4 text-center" style="background-color: #8B4513;">
          <h3 class="text-lg font-semibold mb-2 text-white text-center"
              style="font-family: 'Lora', serif;">Almojabanas</h3>

          <!-- Add to Cart Button -->


        </div>
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
      </div>

      <div
          class="product-card relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl border-none"
          style="background-color:
        #8B4513;" data-delay="0.6s">
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
        <!-- Product Image -->
        <img src="/images/products/pan-de-queso.jpg" alt="Product Name" class="w-full h-60
    object-cover">

        <!-- Product Details -->
        <div class="p-4 text-center" style="background-color: #8B4513;">
          <h3 class="text-lg font-semibold mb-2 text-white text-center"
              style="font-family: 'Lora', serif;">Pan de Queso</h3>

          <!-- Add to Cart Button -->


        </div>
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">

      </div>

      <div
          class="product-card relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl border-none"
          style="background-color:
        #8B4513;" data-delay="0.9s">
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
        <!-- Product Image -->
        <img src="/images/products/pan-coco.jpeg" alt="Product Name" class="w-full h-60
    object-cover">

        <!-- Product Details -->
        <div class="p-4 text-center" style="background-color: #8B4513;">
          <h3 class="text-lg font-semibold mb-2 text-white text-center"
              style="font-family: 'Lora', serif;">Pan de Coco</h3>

        </div>
        <img src="/images/decorative/heading.jpg" class="h-12 w-full">
      </div>
    </div>

    <div class="px-16 text-center w-full">
      <p class="text-black text-2xl text-bold mb-12" style="font-family: 'Lora', serif;
        font-weight:
        bold;">
        Interested in seeing what more we have to offer? Click below to view our entire
        menu.
      </p>
      <router-link to="/products" style="font-family: 'Lora', cursive;"
                   class="product-button mt-5 bg-yellow-400 text-white py-3 px-5 rounded-lg
                hover:bg-red-700
                transition duration-300 w-auto text-2xl font-bold">
        Our Menu
      </router-link>
    </div>
  </section>

  <section class="p-0 mb-20 mt-4" id="our_story">

    <!-- Flex container for the animations and text -->
    <div class="flex items-center justify-center">


      <!-- Left animation wrapper with controlled size -->
      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-left-2" class="animation-left w-full h-full"></div>
      </div>

      <!-- Central text -->
      <div class="text-center mt-0 mb-4">
        <!-- Container with relative positioning and responsive width -->
        <div class="">

          <div class="ml-0 mt-16">
            <h1 class="text-black text-5xl sm:text-5xl md:text-6xl lg:text-7xl
                xl:text-6xl font-bold
                p-2 max-w-full section-heading">Our Story
            </h1>

          </div>
        </div>

      </div>

      <!-- Right animation wrapper with controlled size -->
      <div class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center
    justify-center">
        <div id="animation-right-2" class="animation-right w-full h-full"></div>
      </div>
    </div>


    <div class="text-center w-full md:w-3/4 lg:w-1/2 mx-auto p-8 mt-16">
      <p class="text-2xl text-black text-bold m-2 text-center" style="font-family: 'Lora',
      serif;
        font-weight: bold;">"At Delicias Del Pan Bakery we are dedicated to making and
        selling high-quality artisanal bread, with natural ingredients, following
        traditional Colombian recipes."
        <br>
      </p>
    </div>

    <div class="container p-8 mx-auto rounded-lg">

      <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12">

        <div class="bg-yellow-400 p-6 text-center flex flex-col items-center rounded-md
            virtue-card" data-delay="0.3s">

          <!-- Circle with Text Centered Inside -->
          <div class="w-20 h-20 -mt-16 mb-4 bg-yellow-400 rounded-full flex items-center
                justify-center text-white font-bold border-4 border-white">
            <img src="/icons/coffee-beans.png">
          </div>

          <!-- Heading Text -->
          <h1 class="text-2xl font-bold mb-2" style="font-family: 'Lora', serif;">
            Authenticity in every flavor
          </h1>

          <p class="text-lg font-bold" style="font-family: 'Lora', serif;">
            We bring you the rich, traditional flavors of Colombia, honoring recipes
            passed down through generations. Every ingredient is chosen with care to
            ensure an authentic experience.
          </p>

        </div>


        <div class="bg-blue-400 p-6 text-center flex flex-col items-center rounded-md
            virtue-card" data-delay="0.6s">

          <!-- Circle with Text Centered Inside -->
          <div class="w-20 h-20 -mt-16 mb-4 bg-blue-400 rounded-full flex items-center
                justify-center text-white font-bold border-4 border-white">
            <img src="/icons/chef-hat.png">
          </div>

          <!-- Heading Text -->
          <h1 class="text-2xl font-bold mb-2" style="font-family: 'Lora', serif;">
            Freshness In Every Bite
          </h1>

          <p class="text-lg font-bold" style="font-family: 'Lora', serif;">Our commitment
            to
            freshness
            means using only
            the
            finest ingredients daily. From dough to table, everything is made with care
            for the freshest taste.</p>


        </div>

        <div class="bg-red-400 p-6 text-center flex flex-col items-center rounded-md
            virtue-card" data-delay="0.9s">

          <!-- Circle with Text Centered Inside -->
          <div class="w-20 h-20 -mt-16 mb-4 bg-red-400 rounded-full flex items-center
                justify-center text-white font-bold border-4 border-white">
            <img src="/icons/heart.png">
          </div>

          <!-- Heading Text -->
          <h1 class="text-2xl font-bold mb-2" style="font-family: 'Lora', serif;">
            Crafted With Love
          </h1>

          <p class="text-lg font-bold" style="font-family: 'Lora', serif;">Baking is our
            passion, and each product is crafted with care, skill, and a love for
            tradition. We believe every pastry tells a story.</p>

        </div>


      </div>
      <!-- Add more columns if needed -->
    </div>

    <div class="container p-8 mx-auto mt-24 text-black">
      <!-- Main Grid Layout for 'Meet the Founders' Section -->
      <div class="grid grid-cols-1 custom-lg:grid-cols-2 gap-8">

        <!-- Left Column: Text Content -->
        <div class="p-4 justify-center">
          <p class="text-4xl font-bold ml-3 text-black"
             style="font-family: 'Lora', cursive;">Meet
            The Founders</p>
          <p class="text-xl ml-3 text-black" style="font-family: 'Lora', cursive;">
            <i>How Our Journey Began</i>
          </p>
          <p class="text-xl font-bold ml-3 mt-4 text-black" style="font-family: 'Lora',
          cursive;">
            Meet Leidy and Orlando, the founders of Delicias Del Pan Bakery. Delicas Del Pan
            is cultivated from years of artisinal experience, their roots stem from the
            country of Colombia. Click below to read more about their delightful journey.
          </p>

          <div class="p-8 mx-auto md:flex md:gap-8 md:items-start justify-center">
            <!-- Founded Section -->
            <div class="m-2 text-center md:text-left md:w-full flex flex-col justify-start">
              <p class="text-3xl font-bold mt-8 md:border-t-4 border-blue-500 text-black"
                 style="
                        font-family: 'Lora',
        cursive;" id="founded_text">
                Founded: 2023
              </p>
              <p class="text-xl mt-2 leading-relaxed text-black"
                 style="font-family: 'Lora', cursive;">
                Delivering Colombian delights since 2023.
              </p>
            </div>

            <!-- Products Section -->
            <div class="m-2 text-center md:text-left md:w-full flex flex-col justify-start">
              <p class="text-3xl font-bold md:border-t-4 border-red-600 mt-8
                        text-underline-blue text-black
" style="
        font-family: 'Lora', cursive;">
                30+ Products
              </p>
              <p class="text-xl mt-2 leading-relaxed text-black"
                 style="font-family: 'Lora', cursive;">
                Choose from any one of our delicious products.
              </p>
            </div>
          </div>

          <div class="text-center mt-4">
            <a href="#">
              <router-link to="/about"
                           class="bg-yellow-400 px-6 py-3 rounded-lg text-white text-2xl
                        font-bold hover:bg-red-700 transition duration-300"
                           style="font-family: 'Lora', cursive;">
                Read More
              </router-link>
            </a>
          </div>
          <!-- Products Section, centered block with right-aligned text on mobile -->
        </div>

        <!-- Right Column: Image Content -->
        <div class="relative w-full overflow-hidden" style="height: 40rem;">
          <img src="/images/about/WhatsApp Image 2024-12-23 at 08.41.38.jpeg"
               class="w-full object-cover rounded-md"
               alt="Main Image">
        </div>
      </div>
    </div>

    <!-- Your existing homepage content -->


  </section>

  <section class=" p-0 mb-0" id="testimonials">
    <!-- Flex container for the animations and text -->
    <div class="flex items-center justify-center">


      <!-- Left animation wrapper with controlled size -->
      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-left-3" class="animation-left w-full h-full"></div>
      </div>

      <!-- Central text -->
      <div class="text-center mt-16 mb-4">
        <!-- Container with relative positioning and responsive width -->
        <div class="">

          <div class="ml-0">
            <h1 class="text-dark text-5xl sm:text-5xl md:text-6xl lg:text-7xl
                xl:text-6xl font-bold
    p-2 max-w-full section-heading text-black">What Others Are Saying
            </h1>

          </div>
        </div>

      </div>

      <!-- Right animation wrapper with controlled size -->
      <div class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center
    justify-center">
        <div id="animation-right-3" class="animation-right w-full h-full"></div>
      </div>
    </div>
    <div class="p-0 lg:p-20 mx-auto">
      <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grids-col-1
        testimonial-custom:grid-cols-2 gap-4
        place-items-center w-full">

        <div class="testimonial items-center lg:flex med:col lg:p-8">

          <img src="/images/testimonials/Image%2010-27-24%20at%206.42%E2%80%AFPM%20(3).jpeg"
               class="h-96 w-96 rounded-lg mx-auto">
          <p class="text text-center text-bold place-items-center p-12 text-black"
             style="font-family:
                'Lora', serif; font-weight: bolder;">
            "La panadería colombiana que visité tenía una variedad increíble de productos
            frescos.
            Sus pandebonos eran absolutamente deliciosos, con una textura perfecta y un sabor
            auténtico.
            Además, el ambiente era acogedor y el servicio muy amable. Definitivamente
            volveré."

          </p>
        </div>

        <div class="testimonial items-center lg:flex lg:p-8">

          <img src="/images/testimonials/Image%2010-27-24%20at%206.42%E2%80%AFPM%20(2).jpeg"
               class="h-96 w-96 rounded-lg mx-auto">
          <p class="text text-center text-bold place-items-center p-12 text-black"
             style="font-family:
                'Lora', serif; font-weight: bolder;">
            "Artesal Pan es mi panadería favorita en Iowa. Sus productos son siempre frescos y
            deliciosos.
            Recomiendo especialmente sus croissants, que son increíblemente hojaldrados y
            sabrosos.
            El ambiente es acogedor y el servicio es excepcionalmente amable. Siempre que paso
            por allí,
            no puedo resistirme a llevar algo para disfrutar en casa."
          </p>
        </div>

        <div class="testimonial items-center lg:flex lg:p-8">

          <img src="/images/testimonials/Image%2010-27-24%20at%206.42%E2%80%AFPM.jpeg"
               class="h-96 w-96 rounded-lg mx-auto">
          <p class="text text-center text-bold place-items-center p-12 text-black"
             style="font-family:
                'Lora', serif; font-weight: bolder;">
            "El pan de masa madre de Artesal Pan en Iowa es simplemente excepcional. La
            fermentación lenta
            y natural le da un sabor y una textura únicos. Recomiendo especialmente el pan de
            centeno,
            que tiene un ligero sabor a nuez y una corteza crujiente que lo hace perfecto para
            acompañar cualquier comida.
            Es reconfortante saber que cada bocado está lleno de autenticidad y cuidado
            artesanal."
          </p>

        </div>

        <div class="testimonial items-center lg:flex p-0 lg:p-8">

          <img src="/images/testimonials/Image%2010-27-24%20at%206.42%E2%80%AFPM%20(1).jpeg"
               class="h-96 w-96 rounded-lg mx-auto">
          <p class="text text-center text-bold place-items-center p-12 text-black"
             style="font-family:
                'Lora', serif; font-weight: bolder;">
            "Mi hijo es un verdadero fanático del pan de Artesal Pan en Cali. Cada vez que
            pasamos
            frente a la panadería, no puede evitar pedirme que entremos para comprar su pan
            favorito:
            las roscas de chocolate. Sus ojos se iluminan de alegría cuando muerde una porción
            y el
            chocolate se derrite en su boca. Es maravilloso ver cómo disfruta de algo tan
            simple pero
            tan delicioso. Sin duda, Artesal Pan se ha convertido en nuestro lugar favorito
            para
            consentirnos con pan fresco y delicioso."
          </p>
        </div>
      </div>
    </div>

  </section>

  <section class="mb-28" id="social_media">
    <div class="flex items-center justify-center">

      <!-- Left animation wrapper with controlled size -->
      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-left-1" class="animation-left w-full h-full"></div>
      </div>

      <!-- Central text -->
      <div class="text-center mt-16 mb-4">
        <!-- Container with relative positioning and responsive width -->
        <div class="">

          <div class="ml-0">
            <h1 class="text-5xl sm:text-5xl md:text-6xl lg:text-7xl
                xl:text-6xl font-bold
    p-2 max-w-full text-black">@Delicias Del Pan
            </h1>


          </div>
        </div>

      </div>

      <!-- Right animation wrapper with controlled size -->
      <div class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center
    justify-center">
        <div id="animation-right-1" class="animation-right w-full h-full"></div>
      </div>
    </div>

    <div class="container mx-auto p-6">
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        <!-- Image 1 (large), spans 2 columns and 2 rows on large screens -->
        <div class="lg:col-span-2 lg:row-span-2 overflow-hidden relative group">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-2-1024x1024.jpeg"
              alt="Social Media 1"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 2 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-4-1024x1024.jpeg"
              alt="Social Media 2"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 3 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-5-1024x1024.jpeg"
              alt="Social Media 3"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 4 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-14-1024x1024.jpeg"
              alt="Social Media 4"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 5 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-15-1024x1024.jpeg"
              alt="Social Media 5"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 6 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/social_media/WhatsApp-Image-2024-02-29-at-5.48.36-PM-16-1024x1024.jpeg"
              alt="Social Media 6"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 7 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/social_media/7.avif"
              alt="Social Media 7"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 8, spans 2 columns and only 1 row on large screens -->
        <div
            class="col-span-1 row-span-1 lg:col-span-1 lg:row-span-1 overflow-hidden relative group">
          <img
              src="/images/social_media/8.avif"
              alt="Social Media 8"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import {onMounted} from 'vue';
import {gsap} from 'gsap';
import {useHead} from '@vueuse/head';
import {
  animateProductCards,
  animateVirtueCards,
  initializeLottieAnimations,
  animateImagesOnScroll,
} from '../JS/animation_control.js';

export default {
  name: "HomePage",

  setup() {
    useHead({
      title: "Delicias Del Pan Bakery -Authentic Colombian Desserts",
      meta: [
        {
          name: "description",
          content:
              "Enjoy the finest Colombian breads and desserts, made fresh daily! Order now or follow us on social media to experience an authentic taste of Colombia."
        },
        {
          name: "keywords",
          content:
              "Colombian Bakery, Pan De Bono, Arepas, Authentic desserts, Delicias Del Pan"
        },
        {
          property: "og:title",
          content: "Delicias Del Pan - Traditional Colombian Breads & Pastries"
        },
        {
          property: "og:description",
          content:
              "Taste the best Colombian pastries made fresh daily at Delicias del Pan Bakery."
        },
        {
        }
      ],

      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Bakery",
            "name": "Delicias Del Pan Bakery",
            "description":
                "Authentic Colombian bakery serving fresh breads and desserts daily.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1001 S. 6th St",
              "addressLocality": "Council Bluffs",
              "addressRegion": "IA",
              "postalCode": "51509",
              "addressCountry": "US"
            },
            "telephone": "+1-(626)-747-1369",
            "openingHours": "Mo-Fri 08:00-18:00",
            "url": "https://deliciasdelpanbakery.shop"
          })
        }
      ]
    });

    onMounted(() => {
      // Initialize other animations
      animateProductCards();
      animateVirtueCards();
      initializeLottieAnimations();
      animateImagesOnScroll();

      // Set up marquee animation
      const marquee = document.querySelector(".marquee");
      const marqueeContainer = document.querySelector(".marquee-container");

      if (marquee && marqueeContainer) {
        const marqueeWidth = marquee.scrollWidth;

        // Clone marquee content to create continuous scrolling effect
        const clone = marquee.cloneNode(true);
        marqueeContainer.appendChild(clone);

        // GSAP Responsive Animation
        gsap.fromTo(
            ".marquee",
            {x: 0},
            {
              x: -marqueeWidth,
              duration: 30,
              ease: "linear",
              repeat: -1,
            }
        );

        // Extra scaling on mobile
        if (window.innerWidth < 768) {
          gsap.to(".marquee", {
            scale: 1.5,
            transformOrigin: "left center",
            repeat: -1
          });
        }
      }
    });
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Pacifico&display=swap');

body {
  display: grid;
  min-block-size: 100vh;
  place-content: center;
  font-family: system-ui;
  font-size: 1.125rem;
  background-color: var(--clr-primary-800);
}

.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

/* for testing purposed to ensure the animation lined up correctly */
.test {
  background: red !important;
}

/* Product Card Animation in Opposite Direction */
.product-card {
  opacity: 0;
  transform: translate(-100px, 100px); /* Diagonal from bottom right */
  transition: opacity 1s ease, transform 1s ease; /* Slower transition for testing */
}

.product-card.animate {
  opacity: 1;
  transform: translate(0, 0); /* Move to original position */
}


h1, .callToActionText {
  font-family: 'Pacifico', cursive;
  font-weight: 700;
}

#callToActionButton {
  color: white;
  font-weight: bolder;
  font-family: 'Lora', serif;
  background: linear-gradient(to right top, #c8e954, #e9bd26, #fc8d2b, #fe5749, #eb1a6c);
}

#callToAction {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255,
  0), rgba(255, 255, 255, 0), rgba(245, 235, 210, 1)),
  url('../../public/images/main_background.avif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#products {
  background: linear-gradient(to bottom, rgba(245, 235, 210, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

/* Animation applied when in view */
.virtue-card.animate {
  opacity: 1;
  transform: translate(0, 0); /* Move to original position */
}

.virtue-card {
  opacity: 0;
  transform: translate(100px, 50px); /* Off-screen to the left and downwards */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Button Styling */
#callToActionButton {
  opacity: 0;
  animation: animateOpeningText 2s ease forwards, bounce 1750ms infinite;
  animation-fill-mode: forwards; /* Keeps the fade-in state visible */
  animation-delay: 2s;
  display: inline-block;
  background: linear-gradient(to right, #e7da4f, #ffaf50, #ff8670, #fd6b98, #ca66ba, #b564bc, #9f62bd, #8760bd, #a355ae, #b94b9a, #c74384, #cf406c);
  color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Hover Effect for Button */
#callToActionButton:hover {
  box-shadow: 0 0 15px #FFD700, 0 0 30px #FFA500;
  transform: scale(1.05);
}

/* Keyframes for Infinite Bounce Effect */
/* Define the infinite bounce animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(2px);
  }
}

.callToActionText1 {
  animation: animateOpeningText 2500ms ease-in-out;
}

.callToActionText {
  font-family: 'Lora', cursive;
  animation: animateOpeningTextWithDelay 2500ms ease-in-out;
}

@keyframes animateOpeningText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.marquee-container {
  overflow: hidden; /* Hide overflow for smooth scrolling */
  white-space: nowrap;
  width: 100%; /* Full viewport width */
  margin: 0 auto;
  height: 25vh;
}

.marquee {
  display: inline-flex; /* Align items in a row */
}

.marquee-item {
  display: inline-block;
  font-size: 2.5rem;
  white-space: nowrap;
  margin: 0 25px;
}
</style>
