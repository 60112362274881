// src/main.js
import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import router from './router';
import store from './store';  // Import Vuex store
import './assets/tailwind.css';

const app = createApp(App);
const head = createHead();

app.use(router);  // Use the router
app.use(store);   // Use the Vuex store
app.use(head);
app.mount('#app');

// Initialize the cart from localStorage.
store.dispatch('initializeCart');