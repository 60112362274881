<template>
  <section id="our-products" class="mt-20">
    <!-- heading with Colombian illustrations -->
    <div class="flex items-center justify-center">
      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-left-1" class="animation-left w-full h-full"></div>
      </div>

      <!-- Central text -->
      <div class="text-center mt-28 mb-4">
        <div class="">
          <div class="ml-0">
            <h1 class="text-4xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-6xl font-bold p-2 max-w-full text-black"
                id="heading">
              Our Current Menu
            </h1>
          </div>
        </div>
      </div>

      <div
          class="w-52 h-52 sm:w-52 sm:h-52 md:w-64 lg:w-72 lg:h-72 flex items-center justify-center">
        <div id="animation-right-1" class="animation-right w-full h-full"></div>
      </div>
    </div>

    <section id="our-menu" class="mt-2">
      <div class="container mx-auto p-4">
        <!-- Category Sections -->
        <div v-for="category in categories" :key="category.id"
             class="mb-12 pb-12 border-b-2 border-amber-600" style="margin-top: 10vh;">
          <!-- Category Banner with Image -->
          <div class="relative w-full mt-16">
            <img v-if="category.image" :src="category.image"
                 :alt="category.name" class="w-full h-40 object-cover"/>
            <div class="absolute inset-0 flex flex-col justify-center items-start ml-6">
              <h2 class="text-yellow-100 text-5xl font-bold drop-shadow-lg">{{
                  category.name
                }}</h2>
            </div>
          </div>

          <!-- Conditional Carousel/Grid for Products -->
          <div
              v-if="productsByCategory[category.id] && productsByCategory[category.id].length > 4">
            <!-- Display Carousel if more than 4 products -->
            <Splide :options="splideOptions"
                    class="mt-12 p-16 one-col:p-16 two-cols:p-0 lg:p-10 xl:p-0">
              <SplideSlide v-for="product in productsByCategory[category.id]"
                           :key="product.id">
                <div
                    class="product-card transition transform duration-400 hover:-translate-y-3"
                    @click="openAnimatedModal(product)"
                    :class="{animateCard: isAnimating && selectedProduct?.id === product.id}">
                  <img :src="product.image" :alt="product.name"
                       class="w-full h-56 object-center mb-4"/>
                  <h3 class="text-2xl font-semibold text-black text-left p-0"
                      style="font-family: 'Lora', cursive;">
                    {{ product.name }}
                  </h3>
                </div>
              </SplideSlide>
            </Splide>
          </div>

          <div v-else
               class="grid above-two-cols:grid-cols-4 two-cols:grid-cols-2 one-col:grid-cols-1 gap-16 mt-12 p-24 one-col:p-24 two-cols:p-0 lg:p-10 xl:p-0">
            <!-- Display Grid if 4 or fewer products -->
            <div v-for="product in productsByCategory[category.id]" :key="product.id"
                 class="product-card transition transform duration-400 hover:-translate-y-3"
                 @click="openAnimatedModal(product)"
                 :class="{animateCard: isAnimating && selectedProduct?.id === product.id}">
              <img :src="product.image" :alt="product.name"
                   class="w-full h-56 object-center mb-4"/>
              <h3 class="text-2xl font-semibold text-left text-black p-0"
                  style="font-family: 'Lora', cursive;">{{ product.name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>


  <!-- Modal with Transition -->
<transition name="flip-scale">
  <div v-if="isModalOpen" class="modal-overlay h-full" @click.self="closeModal">
    <div class="modal-content p-4 bg-brown-700 rounded-xl pb-22">
      <h2 class="text-center text-white text-5xl font-bold mb-12"
          style="font-family: 'Lora', cursive;">
        {{ selectedProduct?.name }}
      </h2>

      <!-- Product Image and Description -->
      <div
          class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center mb-12">
        <img v-if="selectedProduct?.image" :src="selectedProduct?.image"
             class="h-96 w-full">
        <p class="text-2xl font-bold text-left p-12">{{ selectedProduct?.description }}</p>
      </div>

      <!-- Quantity Input and Add to Cart Button -->
      <div class="flex items-center space-x-4 justify-center">
        <input step="any" v-model="quantity" type="number" id="quantity-input"
               class="block w-1/6 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-2xl no-step"/>
        <button @click="addToCart"
                class="bg-amber-400 px-5 py-3 rounded-md text-white text-3xl font-bold"
                style="font-family: 'Lora', serif;">Add To Cart</button>
      </div>

      <p class="text-3xl font-bold p-8 text-white font-bold"
         style="font-family: 'Lora', serif;">
        ${{ selectedProduct?.price }}</p>

      <p class="text-3xl text-white" style="font-family: 'Pacifico', cursive">
        Delicias Del Pan Bakery&#8482;
      </p>
    </div>
  </div>
</transition>

</template>

<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import api from '../api';
import {initializeLottieAnimations} from "@/JS/animation_control"; // Importing Lottie initialization
// eslint-disable-next-line no-unused-vars
import {useStore} from 'vuex';

export default {
  name: 'OurProducts',
  components: {Splide, SplideSlide},

  props: {
    product: Object,
  },

  data() {
    return {
      quantity: 1, //default quantity.
      isModalOpen: false,
      isAnimating: false,
      categories: [],
      productsByCategory: {},
      selectedProduct: null,
      splideOptions: {
        type: 'loop',
        perPage: 4,
        gap: '1rem',
        pagination: true,
        arrows: true,
        breakpoints: {
          1480: {perPage: 4, gap: '1rem', arrows: true},
          1320: {perPage: 3, gap: '1rem', arrows: true},
          850: {perPage: 2, gap: '0.5rem', arrows: false},
          640: {perPage: 1, gap: '0.5rem', arrows: false},
        },
      },
    };
  },


  mounted() {
    // Initialize the animations when the component is mounted
    initializeLottieAnimations();
    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      try {
        const [productsResponse, categoriesResponse] = await Promise.all([
          api.get('/products/'),
          api.get('/categories/'),
        ]);

        this.categories = categoriesResponse.data;
        this.productsByCategory = this.groupProductsByCategory(productsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },


    groupProductsByCategory(products) {
      return products.reduce((acc, product) => {
        const categoryId = product.category;
        if (!acc[categoryId]) acc[categoryId] = [];
        acc[categoryId].push(product);
        return acc;
      }, {});
    },

    openAnimatedModal(product) {
      this.selectedProduct = product;
      this.quantity = 1; //reset the quantity.
      console.log("Opening modal with product:", product);
      this.isAnimating = true;

      setTimeout(() => {
        this.isModalOpen = true;
        setTimeout(() => this.isAnimating = false, 500); // End animation
      }, 100); // Allow card animation to finish
    },

    closeModal() {
      this.isModalOpen = false;
      this.selectedProduct = null;
    },

    addToCart() {
      console.log('Selected Product in addToCart:', this.selectedProduct); // Log before any checks

      if (!this.selectedProduct || !this.selectedProduct.id) {
        alert('Product is invalid');
        return;
      }

      if (this.quantity <= 0) {
        alert('Quantity must be greater than 0');
        return;
      }
      // Dispatch to Vuex (assuming you've set up Vuex for cart management)
      this.$store.dispatch('addToCart', {
        product: this.selectedProduct,
        quantity: this.quantity,
      });

      this.closeModal(); // Close the modal after adding to cart

    },

  },
};
</script>

<style scoped>
/* Base styling for filter items */
/* Transition for opening and closing the mobile menu */
.product-card {
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
.no-step::-webkit-outer-spin-button,
.no-step::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Hides the stepper controls */
  margin: 0; /* Removes extra margin added by some browsers */
}

/* Firefox */
.no-step {
  -moz-appearance: textfield; /* Hides the stepper controls */
}

/* Modal Overlay with Smooth Fade */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.flip-scale-leave-active .modal-overlay {
  opacity: 0;
}

/* Modal Content */
.modal-content {
  width: 100%;
  max-width: 1210px;
  text-align: center;
  transform: scale(0.7);
  opacity: 1;
}

.flip-scale-enter-active, .flip-scale-leave-active {
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.flip-scale-enter {
  transform: scale(0.5) rotateY(0deg);
  opacity: 0;
}

.flip-scale-enter-active {
  opacity: 1;
}

.flip-scale-leave-to {
  transform: scale(0.5) rotateY(180deg);
  opacity: 0;
}

/* Scale-Rotate Transition */
.flip-scale-enter-active, .flip-scale-leave-active {
}

.flip-scale-enter {
  transform: scale(0.5) rotateY(0deg);
  opacity: 0; /* Start content faded out */
}

.flip-scale-enter-active {
  opacity: 1; /* Fade in content smoothly */
}

.flip-scale-leave-to {
  transform: scale(0.5) rotateY(180deg);
  opacity: 0; /* Fade out content smoothly */
}

#heading {
  font-family: 'Pacifico', cursive;
}

.filter-item {
  transition: color 0.3s, transform 0.3s;
}

/* Hover and active styles */
.filter-item:hover {
  color: #8b4513; /* Custom brown color for hover */
  transform: translateY(20px); /* Slight scale-up on hover */
}

.active {
  color: #8b4513; /* Custom brown color for active filter */
  font-weight: bold;
  text-decoration: underline;
  background-color: brown;
}

</style>